<template lang="pug">
    v-container.how-to-use.px-0()
        v-layout.section-header
            .section-header__title.text-uppercase
                |How to &nbsp;
                span.custom-accent use &nbsp;
                b Scene Finder as a Contributor?
                v-divider.section-header__divider(dark)
        v-item-group(style="z-index:1;").how-to-use__steps.mb-4
            v-container(grid-list-md='')
                v-layout(wrap='')
                    v-flex(v-for='item, index in steps', :key='index', xs4='')
                        v-item
                            v-card.d-flex.align-center.custom-card(:ripple="false" flat style=''  slot-scope='{ active, toggle }',   @click='toggle')
                                v-layout(column justify-center align-center @click='setContent(index)').step-content.text-xs-center
                                    .step-content__tab-header.mb-2 {{item.tabtitle}}
                                    .custom-step
                                      img.custom-step__icon(:src="item.icon")
                                    //- v-img.step-icon(:class="'icon__'+index" contain :width='item.size.width' :height='item.size.height' :src='item.icon')
                                    h5.step-content__title.text-uppercase {{item.title}}
                                    <!--.text-xs-center(v-if='active')-->
                                        <!--| {{item.content}}-->

        transition(name='fade', absolute enter-active-class='fadeInUp', leave-active-class='fadeOutUp')
            v-layout(:key="selectedStep.title").section-header
                v-layout.section-description
                  p.text-justify
                   |{{selectedStep.content}}

</template>

<script>
  export default {
    data: () => ({
      selectedStep: {
        title: '',
        content: '',
      },
      steps: [
        {
          tabtitle: 'step 1',
          icon: require('@/assets/images/step1-special.png'),
          title: 'Capture',
          content: 'See something? A rear-end accident, struck parked vehicle, accident at an intersection, vandalism, break-ins, burglary, trespassing or other crimes. Capture using your phone, dashboard or surveillance camera.',
          size: {
            width: '38.5pt',
            height: '52pt',
          },
        },
        {
          tabtitle: 'step 2',
          icon: require('@/assets/images/step3-special.png'),
          title: 'Share',
          content: 'Anonymously share your findings with insurance companies and others after they have been reviewed and verified on our platform.',
          size: {
            width: '48.5pt',
            height: '52pt',
          },
        },
        {
          tabtitle: 'step 3',
          icon: require('@/assets/images/reward.png'),
          title: 'Earn rewards',
          content: 'Get cash rewards once your content is found by insurance claims handlers, claim adjusters and other professionals. Rewards are placed on your user account and you can withdraw to your bank, our Scene Finder visa card or donate to your favorite charity.',
          size: {
            width: '38.5pt',
            height: '52pt',
          },
        },
      ],

    }),
    computed: {
      profile () {
        return this.$store.state.users.profile
      },
    },
    created () {
      this.setContent(0)
    },
    methods: {
      setContent (index) {
        this.selectedStep = {
          title: this.steps[index].title,
          content: this.steps[index].content,
        }
      },

    },
  }
</script>

<style lang="scss">
  .section-description{
    p {
      font-size: 18px
    }
  }
</style>
