<template lang="pug">
    v-container.videos.px-0.text-center(fluid)
        // Confirmation buy dialog
        v-dialog(v-model='confirmationDialog' width="500px" persistent transition="dialog-bottom-transition")
          div(style ='position:relative; height: 100%;')
            confirmDialog(v-if="confirmationDialog" :item="currentItem" @close="confirmationDialog=false")
            div(style='position:absolute; top:0; width:100%;')
              v-btn.mt-2.ml-2(absolute right  icon color='danger' style="top: -45px; z-index:999;" medium,  dark='', @click.native='confirmationDialog=false')
                v-icon(size="30") mdi-close-box
        // Content
        v-layout.section-header
            .section-header__title.text-uppercase.mt-10
                |Recent &nbsp;
                b Videos
            v-divider.section-header__divider.mb-5(dark)
        carousel.mb-10(:paginationEnabled='false' :perPage=1  :minSwipeDistance= 2)
            slide.slide(v-for='(video, i) in media')
                v-hover
                    v-card.video-card(to="/")
                      .video-card__img-wrapper
                        v-img(:src='video.thumbnail' height="180px" width="100%" style="clip-path: inset(1rem);")
                      v-layout
                        v-flex.justify-center.video-card__title
                          h2 {{video.title}}
                      v-layout
                        v-flex.d-flex.align-center.justify-center
                          img(src="@/assets/images/location-icon.png")
                          span.ml-2.video-card__location {{video.location.direction}}, {{video.location.state}}
                      v-layout
                        v-layout.d-flex.align-center.justify-center(col="6")
                          img(src="@/assets/images/calendar-icon.png")
                          p.ml-2.video-card__date {{video.date}}
                        v-layout.d-flex.align-center.justify-center(col="6")
                          img(src="@/assets/images/time-icon.png")
                          p.ml-2.video-card__time {{video.time}}
                      v-layout
                        v-layout(col="4")
                          v-flex.d-flex.align-center.justify-center
                            p.mb-0.video-card__price {{video.price | moneyFormat}}
                        v-layout(col="4")
                          v-flex.d-flex.align-center.justify-center
                            v-btn.text-uppercase.mx-auto.video-card__buy(color="red" @click="openConfirmationDialog(video)") Buy
                        v-layout(col="4")
                          v-flex.d-flex.align-center.justify-center
                            router-link.video-card__show-more(to="/" tag="a") Show more
</template>

<script>

  import propertyImage from '@/assets/images/icon-faq.png'
  import mapImage from '@/assets/images/icon-terms.png'
  import loaders from '@/plugins/mixins/loaders'
  import { Carousel, Slide } from 'vue-carousel'

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })
  export default {
    components: {
      Carousel,
      Slide,
      confirmDialog: () => import('@/components/custom/dialogs/ConfirmPurchase.vue'),
    },

    filters: {
      moneyFormat: function (value) {
        return formatter.format(value)
      },
    },

    mixins: [loaders],
    data: () => ({
      propertyImage: propertyImage,
      mapImage: mapImage,
      reviews: 413,
      value: 4.5,
      loading: false,
      items: [],
      search: null,
      select: null,
      showSlider: false,
      options: {
        selector: '.siema',
        duration: 200,
        easing: 'ease-out',
        perPage: 1,
        startIndex: 1,
        draggable: true,
        multipleDrag: true,
        threshold: 20,
        loop: false,
        rtl: false,
      },
      confirmationDialog: false,
      currentItem: null,
    }),
    computed: {
      showHeader () {
        return this.$route.path.indexOf('/property/')
      },
      propertyList () {
        return this.$store.getters.getProperties
      },
      media () {
        return this.$store.getters['uploads/getMedia']
      },
    },
    watch: {
      search (val) {
        val && val !== this.select && this.querySelections(val)
      },
      propertyList (val) {
        const vm = this
        if (val.length > 0) {
          setTimeout(function () {
            vm.showSlider = true
          }, 1000)
        }
      },
    },
    created () {
      const vm = this
      vm.loadMedia()
    },
    methods: {
      querySelections (v) {
        this.loading = true
        // Simulated ajax query
        setTimeout(() => {
          this.items = this.states.filter(e => {
            return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
          })
          this.loading = false
        }, 500)
      },
      openConfirmationDialog (download) {
        const vm = this
        vm.currentItem = download
        vm.confirmationDialog = true
      },
    },

  }
</script>

<style lang="scss">
    $primary-color: var(--secondary-color);
    $text-color: var(--grey-color);
    .video-card{
      width: 300px;
      margin: 0 auto;
      &__title,
      &__description,
      &__location,
      &__time,
      &__date{
        text-transform: uppercase;
        color: #a3a3a3;
      }
      &__buy{
        font-size: 18px !important;
      }
      &__show-more{
        color: var(--secondary-color) !important;
        font-size: 15px !important;
      }
      &__price{
        font-weight: bold;
        font-size: 20px;
        color: var(--secondary-color);
      }
    }
    .videos{
        .section-header{
            color: $text-color;
            font-size: 19.5pt;
            line-height: 19.8pt;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 1rem;

            &__title, &__title-2{
                letter-spacing: -0.9pt;
                b{
                    color: #8a8a8a;
                    font-weight: 800;
                }
            }
            &__title-2{
                font-size: 16.3pt;
            }
            &__divider{
                width: 115.3pt;
                border-color: $primary-color !important;
                border-width: 0.5pt;
                margin:auto;
                margin-top: 7pt;
                letter-spacing: -0.81pt;
            }
        }
    }
</style>
