<template lang="pug">
  .home-wrapper.px-0
    .home-wrapper__brand.text-center
      img(:src="brandIcon")
      img.bg-overlay(:src="bannerBg")
    .display-1.text-center() Capture, Upload, Share evidence-based image and video for a reward
    HowToUse.white.pt-10.mb-5.mt-10
    .upload-wrapper.text-center.my-10.container()
      // Upload
      v-dialog( persistent :value='uploadDialog'  max-width='800px'   transition="dialog-bottom-transition")
        div(style ='position:relative; height: 100%;')
          upload-videos(:model ='video' v-if="uploadDialog" @close="uploadDialog=false")
      v-row
        v-col
          img( src="@/assets/images/upload.png" )
      v-row
        v-col
          h2.upload__title Let’s upload content
      v-row
        v-col
          p.upload__description Add media from your phone or Open camera to capture a scene.
      v-row
        v-col
          v-btn.load-button.mr-0(color="secondary" class="text-uppercase pr-3" rounded @click="uploadDialog = true")
            img(class="" src="@/assets/images/icon-btn-2.png")
            span(class="pl-2 ") Gallery
        v-col
          v-btn.load-button.mr-0(color="accentTwo" class="text-uppercase" rounded @click="$router.replace('/contributor/camera')")
            img(class="" src="@/assets/images/icon-btn-1.png")
            span(class="pl-2 ") Open camera
</template>

<script>
  import bannerBg from '@/assets/images/banner-bg.png'
  import brandIcon from '@/assets/images/brand-icon-dsk.png'
  import HowToUse from './component/HowToUse'
  import Explore from '@/components/custom/Explore'
  import Videos from '@/components/custom/Videos'
  export default {
    components: {
      HowToUse,
      Explore,
      Videos,
      UploadVideos: () => import('@/components/custom/dialogs/Videos/UploadsDialog.vue'),
      Camera: () => import('./component/Camera'),
    },
    data () {
      return {
        bannerBg: bannerBg,
        brandIcon: brandIcon,
        video: '',
        uploadDialog: false,
        cameraDialog: false,
      }
    },
    computed: {},
  }
</script>
